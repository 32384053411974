<template>
  <div class="eventList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(events)"
        :columns="kgm_responsiveColumns()"
        :filterable="false"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(events)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="dateTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span v-tooltip="dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
          </td>
        </template>
        <template
          slot="acknowledgedTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            {{ textByAcknowledged(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>
        <template
          slot="sourceTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            {{ textBySource(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js'

export default {
  name: "EventList",
  mixins: [
    dateTimeMixin,
    kendoGridMixin
  ],
  props: {
    triggerId: {
      type: String,
      required: true
    },
    hostId: {
      type: String,
      required: true
    },
    limitEvents: Boolean
  },
  data () {
    return {
      loading: true,
      events: null,
      acknowledgedStatusOptions: [
        { key: 0, class: "orange", text: "unacknowledged" },
        { key: 1, class: "green", text: "acknowledged" }
      ],
      sourceOptions: [
        { key: 0, text: "trigger" },
        { key: 1, text: "discovery rule" },
        { key: 2, text: "active agent auto-registration" },
        { key: 3, text: "internal event" }
      ],
      sourceAndValueOptions: [
        {
          key: 0,
          valueOptions: [
            { key: 0, class: 'green', text: 'ok' },
            { key: 1, class: 'orange', text: 'problem' },
          ]
        },
        {
          key: 1,
          valueOptions: [
            { key: 0, class: '', text: 'host or service up' },
            { key: 1, class: '', text: 'host or service down' },
            { key: 2, class: '', text: 'host or service discovered' },
            { key: 3, class: '', text: 'host or service lost' }
          ]
        },
        {
          key: 2,
          valueOptions: [
            { key: 0, class: '', text: 'normal' },
            { key: 1, class: '', text: 'unknown' }
          ]
        }
      ],
      kgm_sort: [
        {
          "field": "clock",
          "dir": "desc"
        }
      ],
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "source",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_columns: [
        {
          field: 'clock',
          filterable: true,
          filter: 'date',
          title: 'Changed',
          cell: 'dateTemplate',
          width: '160px'
        },
        {
          field: 'acknowledged',
          filterable: true,
          filter: 'boolean',
          title: 'Acknowledged',
          cell: "acknowledgedTemplate"
        },
        {
          field: 'source',
          filterable: true,
          filter: 'text',
          title: 'Type',
          cell: 'sourceTemplate',
          hideOn: ['lgDown']
        }
      ]
    }
  },
  watch: {
    'limitEvents': function (value) {
      this.getEvents(value);  
    }
  },
  created () {
    this.getEvents(true);
  },
  beforeDestroy () {
    this.$emit('setBack');
  },
  methods: {
    textByAcknowledged (value) {
      return this.acknowledgedStatusOptions.find(o => o.key == value).text;
    },
    textBySource (value) {
      return this.sourceOptions.find(o => o.key == value).text;
    },
    getEvents (limit) {
      this.loading = true;
      this.axios.post('/MonitoringSystem/GetEvent', { 'triggerId': this.triggerId, 'hostId': this.hostId }, {
        params: { 'limit': limit}
      } )
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.events = response.data;
          if(this.events.length > 0 && this.events[0].acknowledges) {
            this.$emit("setAck", this.events[0].acknowledges)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>