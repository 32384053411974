var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eventList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.events),"columns":_vm.kgm_responsiveColumns(),"filterable":false,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.events),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"dateTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.dateTime_fromNow(_vm.kgm_getNestedValue(props.field, props.dataItem))),expression:"dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))"}]},[_vm._v(_vm._s(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem))))])])]}},{key:"acknowledgedTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(" "+_vm._s(_vm.textByAcknowledged(_vm.kgm_getNestedValue(props.field, props.dataItem)))+" ")])]}},{key:"sourceTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(" "+_vm._s(_vm.textBySource(_vm.kgm_getNestedValue(props.field, props.dataItem)))+" ")])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }